<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-xl-6">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Update Category</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/categories/list"
              button-text="Update Category"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form id="post_category_form" class="form">
              <div class="form-group">
                <label for="postTitle">Title</label>
                <input
                  v-model="category.title"
                  id="categoryTitle"
                  class="form-control form-control-solid"
                  placeholder="Enter Category title"
                />
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  name: 'EditCategory',
  components: {
    CardToolbar
  },
  data() {
    return {
      category: {
        title: null
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Categories', route: '/categories/list' },
      { title: 'Edit Category' }
    ])

    ApiService.get('/post_categories', this.$route.params.id).then(
      ({ data }) => {
        this.category.title = data.title
      }
    )
  },
  methods: {
    onSubmit() {
      this.patchCategory(this.$route.params.id, this.category).then(
        ({ status }) => {
          if (status === 200) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Category successful update in database'
            })

            setTimeout(() => {
              this.$router.push({ name: 'categories-list' })
            }, 500)
          }
        }
      )
    },
    async patchCategory(id, entity) {
      try {
        return await ApiService.update('/post_categories', id, entity)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
